@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url('fonts/inter-light.woff2') format('woff2'),
       url('fonts/inter-light.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('fonts/inter-regular.woff2') format('woff2'),
       url('fonts/inter-regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('fonts/inter-medium.woff2') format('woff2'),
       url('fonts/inter-medium.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('fonts/inter-semibold.woff2') format('woff2'),
       url('fonts/inter-semibold.woff') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url('fonts/inter-bold.woff2') format('woff2'),
       url('fonts/inter-bold.woff') format('woff');
}
