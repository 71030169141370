/* TODO: RENAME */
#chat-paywall {
    background-color: inherit;
    width: 100%;
    height: 100%;
}

.chat-paywall-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}


    .chat-paywall-container p {
        text-align: center;
        color: #FFF;
        word-wrap: break-word;
    }

    .chat-paywall-container h2 {
        font-weight: 600;
        margin-bottom: 2rem;
        font-size: 27px;
        line-height: 47px;
        margin-top: 2rem;
    }


@media(min-width: 1650px) {
    .chat-paywall-container img {
        width: 200px;
        height: 200px;
    }

    .chat-paywall-container p {
        font-size: 18px;
    }

    .chat-paywall-container h2 {
        font-size: 33px;
    }
}
