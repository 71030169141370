/*------------------------------------------------------------------
DARK SKIN VARIABLES STYLESHEET

Last change: [30 June, 2020]

Chanelog: 

-- Added styles for appbar
-------------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6,
h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #fff; }

.authentication-page p {
  color: #f8f9fa !important; }

.media-link::after {
  background-color: rgba(0, 0, 0, 0); }

.form-group label {
  color: #fff; }

.input-group.bg-light,
.form-group.bg-light {
  background-color: #383f44 !important; }

.input-group .input-group-text,
.form-group .input-group-text {
  border-color: #2a2a2a;
  background-color: #2a2a2a; }

.input-group input.form-control,
.form-group input.form-control {
  caret-color: #adb5bd;
  background-color: #2a2a2a;
  border-color: #2a2a2a; }
  .input-group input.form-control:focus,
  .form-group input.form-control:focus {
    background-color: #2a2a2a;
    border-color: #2a2a2a;
    color: #fff; }

.form-control:focus {
  background-color: #2a2a2a;
  border-color: #2a2a2a; }

textarea.form-control:focus {
  background-color: transparent; }

.custom-file-label {
  background-color: #2a2a2a;
  border-color: #2a2a2a;
  color: #adb5bd; }
  .custom-file-label::after {
    background-color: #383f44;
    border-color: #2a2a2a;
    color: #fff; }

.avatar {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1); }

.avatar.avatar-online:before, .avatar.avatar-offline:before, .avatar.avatar-busy:before, .avatar.avatar-away:before {
  border-color: #323333; }

.btn-outline-default {
  border-color: #2a2a2a;
  background: #2a2a2a;
  color: #adb5bd; }
  .btn-outline-default:hover {
    background: #495057;
    color: #adb5bd; }
  .btn-outline-default:focus {
    box-shadow: none; }

.btn-minimal {
  color: #495057;
  background-color: transparent !important; }
  .btn-minimal:hover {
    color: inherit !important; }
  .btn-minimal:focus {
    color: rgba(33, 37, 41, 0.35); }
  .btn-minimal.active {
    color: inherit; }

.btn-secondary:not(:disabled):not(.disabled):active {
  color: inherit; }

.message-content .btn-primary {
  background: #4e44fe; }

.message .message-content {
  background-color: #383f44;
  color: #b9b9b9; }

.message .avatar img {
  box-shadow: 0px 0px 0px 0.5rem #323333; }

.message .message-options {
  color: #adb5bd; }

.message.self .message-content {
  background-color: #665dfe;
  color: #ffffff; }

.message-divider:before {
  background-color: #424242;
  border-color: #2b2b2f;
  color: #b0b5b9; }

.contacts-list .contacts-item.active .avatar::before {
  border-color: #665dfe; }

.contacts-list .contacts-item.unread .avatar::before {
  border-color: #f4f4ff; }

.contacts-list .contacts-item .contacts-link {
  border-color: #4b4b60;
  color: inherit;
  color: #fff; }

@media (min-width: 1200px) {
  .contacts-list .contacts-item:hover .contacts-link {
    border-color: #665dfe; } }

@media (min-width: 1200px) {
  .contacts-list .contacts-item.active .contacts-link {
    background: #665dfe;
    border: 1px solid #665dfe; }
    .contacts-list .contacts-item.active .contacts-link .chat-name {
      color: #fff; }
    .contacts-list .contacts-item.active .contacts-link .chat-time,
    .contacts-list .contacts-item.active .contacts-link .contacts-texts,
    .contacts-list .contacts-item.active .contacts-link svg,
    .contacts-list .contacts-item.active .contacts-link p {
      color: #f1f1f1 !important; } }

.contacts-list .contacts-item.unread .contacts-link {
  background: #383f44;
  border-color: #383f44; }
  .contacts-list .contacts-item.unread .contacts-link .contacts-texts {
    color: #e8e8e8; }

@media (min-width: 1200px) {
  .contacts-list .contacts-item.unread.active .contacts-link {
    background: #665dfe;
    border: 1px solid #665dfe; }
    .contacts-list .contacts-item.unread.active .contacts-link .chat-name,
    .contacts-list .contacts-item.unread.active .contacts-link .chat-time,
    .contacts-list .contacts-item.unread.active .contacts-link .contacts-texts {
      color: #f1f1f1; } }

.contacts-list .contacts-content .contacts-info .chat-time {
  color: #adb5bd; }

.contacts-list .contacts-content .contacts-texts {
  color: #adb5bd; }

.dropdown.show .btn-secondary.btn-minimal,
.dropdown.show .nav-link {
  color: #495057 !important; }

.dropdown.show .btn-outline-default {
  background-color: #495057; }

.dropdown.show .dropdown-menu {
  background: #2b2b2b; }
  .dropdown.show .dropdown-menu .dropdown-item {
    color: rgba(197, 201, 223, 0.8); }
    .dropdown.show .dropdown-menu .dropdown-item:focus, .dropdown.show .dropdown-menu .dropdown-item:hover {
      background-color: #383f44; }

.modal-content {
  background: #383f44;
  border-color: #2b2b2f; }
  .modal-content h5,
  .modal-content h6 {
    color: #fff; }
  .modal-content .modal-header {
    border-color: #2b2b2f; }
    .modal-content .modal-header .modal-title {
      color: #fff; }
  .modal-content .modal-footer {
    border-color: #2b2b2f; }

.close {
  text-shadow: none; }

.list-group-item {
  background: #383f44;
  border-color: #2b2b2f; }
  .list-group-item p {
    color: #fff; }

.document-body h6,
.media-body h6 {
  color: #fff; }

.document-body p,
.media-body p {
  color: #fff; }

.chat-body .chat-header {
  border-color: #2b2b2f; }

.chat-body .chat-footer {
  border-color: #2b2b2f; }

.chat-info {
  background: #383f44; }
  @media (min-width: 1200px) {
    .chat-info {
      border-color: #2b2b2f; } }
  @media screen and (max-width: 1260px) and (min-width: 1200px) {
    .chat-info.chat-info-visible {
      border-color: #2b2b2f; } }
  .chat-info .chat-info-header {
    border-color: #2b2b2f; }
  .chat-info h5,
  .chat-info h6 {
    color: #fff; }

.main-layout .navigation {
  background: #383f44; }


.main-layout .sidebar {
  background: #2d2d2d; }
  @media (min-width: 1200px) {
    .main-layout .sidebar {
      border-color: #2b2b2f; } }

.main-layout .main {
  background: #323333; }
  @media (max-width: 992px) {
    .main-layout .main {
      background: #323333; } }

.navbar-light .nav-minimal .nav-link {
  color: #aea9fe; }
  .navbar-light .nav-minimal .nav-link:hover {
    color: #dedcff; }
  .navbar-light .nav-minimal .nav-link.active {
    color: #fff; }

.sidebar-header {
  background: #383f44;
  border-color: #2b2b2f; }
  .sidebar-header h5 {
    color: #fff; }

/**********************/
.card-bg-1,
.card-bg-2,
.card-bg-3,
.card-bg-4,
.card-bg-5 {
  background-color: #383f44;
  border-color: #2b2b2f; }

.card {
  border-color: #2b2b2f;
  background-color: #383f44; }
  .card .card-header,
  .card .card-footer {
    background-color: #2a2a2a;
    border-color: #2b2b2f; }
  .card h5,
  .card h6 {
    color: #fff; }

.page-main-heading {
  border-color: #2b2b2f;
  background-color: #383f44; }
  .page-main-heading h5 {
    color: #fff; }

.border-bottom {
  border-color: #2b2b2f !important; }

.appbar {
  background: #383f44; }
  @media (min-width: 1200px) {
    .appbar {
      border-color: #2b2b2f; } }
  .appbar .appbar-head {
    border-color: #2b2b2f;
    color: #f8f9fa;
    background-color: #383f44; }
  .appbar .nav-link {
    color: #adb5bd; }
  .appbar .appbar-wrapper {
    border-color: #2b2b2f; }
    @media (min-width: 1200px) {
      .appbar .appbar-wrapper {
        border-color: #2b2b2f; } }
    .appbar .appbar-wrapper .nav-link {
      color: #b1b1b1; }
  .appbar .appnavbar-content {
    background: #323333; }
    .appbar .appnavbar-content .appnavbar-heading {
      border-color: #2b2b2f;
      background-color: #383f44; }
    .appbar .appnavbar-content .appnavbar-body-title {
      background-color: #383f44;
      border-color: #2b2b2f; }
    .appbar .appnavbar-content .appnavbar-footer {
      border-color: #2b2b2f;
      background-color: #383f44; }

.backdrop {
  background-color: rgba(0, 0, 0, 0.3); }

.translator-container textarea {
  color: #b9b9b9;
  caret-color: #adb5bd;
  background-color: #2a2a2a;
  border-color: #2a2a2a; }
  .translator-container textarea:focus {
    color: #fff; }

.translator-container .card {
  color: #b9b9b9; }

.note-container {
  padding: 0.75rem; }
  .note-container .note {
    border-color: #2b2b2f;
    background-color: #383f44; }
    .note-container .note .note-footer {
      border-color: #2b2b2f; }
    .note-container .note .note-added-on {
      color: #adb5bd; }
    .note-container .note .note-description {
      color: #adb5bd; }

.todo-container .todo-list .todo-task-done .todo-title {
  color: #adb5bd; }

.settings-container .border {
  border-color: #2b2b2f !important; }

.modal-body .form-control {
  color: #b9b9b9; }

.modal-body textarea {
  color: #b9b9b9;
  caret-color: #adb5bd;
  background-color: #2a2a2a;
  border-color: #2a2a2a; }
  .modal-body textarea:focus {
    color: #fff; }

.dropdown-divider {
  border-color: #4b4b60; }

