
#sidebar-main {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 95%;
    z-index: 10;
    position: absolute;
    background-color: inherit;
    position: relative;
}

.top-left-search {
    margin: 0 auto;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (min-width:1150px) {
    #sidebar-main {
        width: 27%;
        padding: 0;
        max-width: auto;
        top: 0;
        left: 0;
        transform: translate(0);
        height: 100%;
        position: relative;
        /*margin-right: 20px;*/
        border-right: 0.5px solid #646464;
        border-left: 0.5px solid #646464;
        border-bottom: 0.5px solid #646464
    }

    .top-left-search {
        margin: 0 auto;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media(min-width: 1650px) {
    #sidebar-main {
        width: 27%;
        /* padding: 1.2rem 1.1rem 1.2rem 1.1rem; */
        height: 100%;
        position: relative;
        border-right: 0.5px solid #646464;
        border-left: 0.5px solid #646464;
        border-bottom: 0.5px solid #646464
    }

    .top-left-search {
        margin: 0 auto;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
