@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.settings-master-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.settings-master-component-container {
    height: 82vh;
    overflow: hidden;
    position: relative;
}

.settings-master-component-wrapper {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

body {
    overflow: hidden;
}

@media(min-width: 1150px) {
    .settings-master-component-container {
        height: 87vh;
    }

    .settings-master-component-wrapper {
        display: flex;
        /*align-items: center;*/
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        height: 100%;
    }
}

@media(min-width: 1650px) {
    .settings-master-component-container {
        height: 87vh;
    }
}

#center-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 10;
    display: none;
}

@media(min-width:1150px) {
    #center-bar {
        width: 73%;
        height: 100%;
        top: 0;
        left: 0;
        transform: translate(0);
        position: relative;
        z-index: 10;
        display: block;
    }
}

@media(min-width: 1650px) {
    #center-bar {
        width: 73%;
        height: 100%;
    }
}

#settings-detail {
    background-color: #495057;
    width: 100%;
    height: 100%;
    position: relative;
}

    #settings-detail .center-settings-content {
        display: flex;
        width: 100%;
        height: calc(100% - 30%);
        flex-direction: column;
        justify-content: flex-start;
        padding: 0.75rem;
        position: relative;
        overflow-y: auto;
    }

.back-to-contact span {
    color: #6B6B6B;
}

#settings-detail .bottom-settings-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 15%;
}



#settings-detail .profile-chat {
    display: flex;
    justify-content: center;
    align-items: center;
}


    #settings-detail .profile-chat h3 {
        margin-left: 10px;
    }

.file_input, .file_input_img {
    display: none;
}

.settings-input {
    display: flex;
    width: calc(100%);
    justify-content: space-between;
    align-items: center;
    background: #F2F2F2;
    padding: 0 15px;
    height: 75%;
    max-height: 50px;
    border-radius: 14px;
}

.input-interactive {
    display: flex;
    align-items: center;
}

.settings-input input {
    padding: 0 8px;
    height: 100%;
    width: 92%;
    border: none;
    outline: none;
    background: transparent;
}

    .settings-input input, .settings-input input::placeholder {
        font-size: .8rem;
        font-weight: 400;
        color: #7D7D7D;
    }

.input-interactive .MuiSvgIcon-root {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 1.2rem !important;
    cursor: pointer;
}

.send .MuiSvgIcon-root {
    font-size: 1.4rem !important;
    transform: rotate(-25deg);
    cursor: pointer;
}

.send {
    margin-left: 15px;
}

.settings-messages-container {
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background: #cccbcb;
    border-radius: 8px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

.center-settings-content-no-messages {
    width: 100%;
    height: calc(100% - 30%);
    display: flex;
    justify-content: center;
    align-content: center;
}

    .center-settings-content-no-messages .settings-messages-container {
        display: inline-flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
    }

        .center-settings-content-no-messages .settings-messages-container img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin: 0 auto;
        }

        .center-settings-content-no-messages .settings-messages-container h3 {
            font-weight: 500;
            font-size: 23px;
            line-height: 45px;
            letter-spacing: 0.02em;
            color: #646464;
            margin-top: 10px;
        }

.emoji-x {
    position: absolute;
    bottom: 2%;
    right: 2%;
}

aside.emoji-picker-react {
    padding: 10px 0;
    border-radius: 12px !important;
}

.back-from-settings {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

    .back-from-settings h4 {
        font-size: 16px;
        font-weight: 400;
        color: #6B6B6B;
        margin-left: 10px;
    }

    .back-from-settings span {
        /* font-size: 25px;
   font-weight: 400;
   color: #6B6B6B; */
        color: #6B6B6B;
    }

.info-chat {
    width: 100%;
    padding: 2rem 2.5rem .9rem 2.5rem;
    color: #717171;
}

    .info-chat h4 {
        font-size: 25px;
        color: #646464;
        font-weight: 400;
    }

    .info-chat em {
        color: #646464;
    }

    .info-chat .info-theme {
        display: flex;
        align-items: center;
    }

        .info-chat .info-theme div {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-left: 10px;
            margin-top: 15px;
        }

    .info-chat p {
        margin-top: 15px;
    }

.back-to-contact {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;
}

@media(min-height:700px) {

    #settings-detail .center-settings-content {
        height: calc(100% - 16%);
    }

    #settings-detail .bottom-settings-content {
        height: 8%;
    }

    .center-settings-content-no-messages {
        height: calc(100% - 16%);
    }
}

@media(min-width: 1650px) {

    #settings-detail .center-settings-content {
        /*padding: 1rem 2rem;*/
    }

    #settings-detail .profile-chat h3 {
        font-size: 19px;
        margin-left: 15px;
    }

    .center-settings-content-no-messages .settings-messages-container img {
        width: 120px;
        height: 120px;
    }

    .center-settings-content-no-messages .settings-messages-container h3 {
        font-size: 25px;
        margin-top: 2rem;
    }

    .settings-input {
        max-height: 75px;
        height: 80%;
    }

    #settings-detail .center-settings-content {
        height: calc(100% - 16%);
    }

    #settings-detail .bottom-settings-content {
        height: 8%;
    }

    .center-settings-content-no-messages {
        height: calc(100% - 16%);
    }

    .send {
        margin-left: 25px;
    }
}


#menu-main {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    position: relative;
    padding: 0.75rem;
    border-style:none;
}

.menu-options {
    padding: 0.75rem;
}

    .menu-options .menu-option {
        width: 100%;
        max-width: 100%;
        cursor: pointer;
        position: relative;
    }

        .menu-options .menu-option .menu-option-link {
            display: flex;
            align-items: center;
            flex-grow: 1;
            max-width: 100%;
            transition: all .3s ease;
            padding: 1rem 0rem;
            margin-bottom: 1rem;
        }

    .menu-options .menu-option-content {
        padding-left: 0.875rem;
        transition: all .4s;
        overflow: hidden;
        max-width: 100%;
        flex: 1 1 auto;
    }

        .menu-options .menu-option-content .menu-option-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.375rem;
        }

            .menu-options .menu-option-content .menu-option-info .menu-option-title {
                font-size: 0.875rem;
                margin-bottom: 0;
                margin-right: auto;
            }

            .menu-options .menu-option-content .menu-option-info .menu-option-forward {
                font-size: 0.875rem;
                display: flex;
                align-items: center;
                white-space: nowrap;
            }

@media (min-width:1150px) {
    #menu-main {
        width: 27%;
        max-width: auto;
        top: 0;
        left: 0;
        transform: translate(0);
        height: 100%;
        position: relative;
    }
}

@media(min-width: 1650px) {
    #menu-main {
        width: 27%;
        /* padding: 1.2rem 1.1rem 1.2rem 1.1rem; */
        height: 100%;
        position: relative;
    }
}

.contact {
    display: flex;
    padding: .9rem 1rem;
    margin-top: 10px;
    border-radius: 18px;
    background: inherit;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 90%;
}

    .contact h3 {
        font-family: "Poppins", sans-serif;
        font-size: 17px;
        font-weight: 400;
        position: relative;
        color: #6B6B6B;
        top: -1px;
    }

.active-contact {
    background-color: #495057;
}

    .active-contact p {
        color: #FFF;
    }

    .active-contact h3 {
        color: #FFF;
    }

.image-section {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
}

.contact img {
    width: 47px;
    object-fit: fill;
    height: 47px;
}

.contact .name-message {
    margin-left: 10px;
}

.lastMessage {
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 13.5px;
    position: relative;
    top: -2px;
}

.bold-text {
    font-weight: 500;
    font-size: 13.5px;
    color: #616161;
}

.online {
    background-color: #6cf85f;
    border: 1px solid white;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 7px;
}

.contact .name-contact {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.contact .image-section {
    position: relative;
}

    .contact .image-section img {
        border-radius: 50%;
    }

.new-message {
    width: 8px;
    height: 8px;
    background: #625AC0;
    border-radius: 50%;
    position: absolute;
    right: 12%;
}

.color-showness {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.plus-btn {
    width: 14px;
    height: 14px;
    background-color: #cacaca;
    border-radius: 50%;
    position: absolute;
    right: 12%;
}

.plus::after {
    content: "";
    width: 2px;
    height: 50%;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.plus::before {
    content: "";
    width: 50%;
    height: 2px;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.min-btn {
    width: 14px;
    height: 14px;
    background-color: #625AC0;
    border-radius: 50%;
    position: absolute;
    right: 12%;
}

.min::before {
    content: "";
    width: 50%;
    height: 2px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
}

@media(min-width:1650px) {
    .contact {
        display: flex;
        padding: .9rem 1rem;
        margin-top: 18px;
        border-radius: 18px;
        align-items: center;
        cursor: pointer;
        position: relative;
        width: 90%;
    }
}
