@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

#chat-detail {
    background-color: #495057;
    width: 100%;
    height: 100%;
    position: relative;
}

    #chat-detail .top-chat-content {
        display: flex;
        width: 100%;
        border-bottom: .5px solid #646464;
        padding: 5px 15px;
        justify-content: space-between;
        align-items: center;
        height: 15%;
    }

    #chat-detail .center-chat-content {
        display: flex;
        width: 100%;
        height: calc(100% - 30%);
        flex-direction: column-reverse;
        justify-content: flex-start;
        /*padding: 0 2.5rem;*/
        position: relative;
        overflow-y: auto;
        /*padding: 1.1rem 2rem .9rem 2rem;
        padding: 15px 15px;*/
    }

.back-to-contact span {
    color: #6B6B6B;
}

#chat-detail .bottom-chat-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 15%;
}

#chat-detail .top-chat-content img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}


#chat-detail .profile-chat {
    display: flex;
    justify-content: center;
    align-items: center;
}

#chat-detail .left-top-chat-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

#chat-detail .profile-chat h3 {
    margin-left: 10px;
}

.top-chat-content .MuiSvgIcon-root {
    fill: #717171 !important;
    cursor: pointer;
}

.file_input, .file_input_img {
    display: none;
}

#bottom-bar-chat {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    min-height:15px
}

.chat-input {
    display: flex;
    width: calc(100%);
    justify-content: space-between;
    align-items: center;
    background: #F2F2F2;
    padding: 0 15px;
    height: 75%;
    max-height: 50px;
    border-radius: 14px;
}

.input-interactive {
    display: flex;
    align-items: center;
}

.chat-input input {
    padding: 0 8px;
    height: 100%;
    width: 92%;
    border: none;
    outline: none;
    background: transparent;
}

    .chat-input input, .chat-input input::placeholder {
        font-size: .8rem;
        font-weight: 400;
        color: #7D7D7D;
    }

.input-interactive .MuiSvgIcon-root {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 1.2rem !important;
    cursor: pointer;
}

.send .MuiSvgIcon-root {
    font-size: 1.4rem !important;
    transform: rotate(-25deg);
    cursor: pointer;
}

.send {
    margin-left: 15px;
}

.chat-messages-container {
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background: #cccbcb;
    border-radius: 8px;
}

::-webkit-scrollbar-track {
    background: #fff;
}

.center-chat-content-no-messages {
    width: 100%;
    height: calc(100% - 30%);
    display: flex;
    justify-content: center;
    align-content: center;
}

    .center-chat-content-no-messages .chat-messages-container {
        display: inline-flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
    }

        .center-chat-content-no-messages .chat-messages-container img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin: 0 auto;
        }

        .center-chat-content-no-messages .chat-messages-container h3 {
            font-weight: 500;
            font-size: 23px;
            line-height: 45px;
            letter-spacing: 0.02em;
            color: #646464;
            margin-top: 10px;
        }

.emoji-x {
    position: absolute;
    bottom: 2%;
    right: 2%;
}

aside.emoji-picker-react {
    padding: 10px 0;
    border-radius: 12px !important;
}

.more-chat {
    display: inline-flex;
    background: #FCFCFC;
    box-shadow: 0px 4px 15px rgba(202, 202, 202, 0.25);
    border-radius: 12px;
    flex-direction: column;
    align-content: center;
    padding: 10px 10px;
    position: absolute;
    top: 10%;
    right: 7%;
    z-index: 100;
}

    .more-chat .content-more-chat {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        padding: 15px 20px;
        border-radius: 14px;
    }

        .more-chat .content-more-chat:hover {
            background: #f0f0f0;
        }

        .more-chat .content-more-chat h4 {
            margin-left: 10px;
            margin-right: 30px;
            font-weight: 400;
            font-size: 14px;
            color: #717171;
        }

    .more-chat span {
        font-size: 1.5rem !important;
        color: #717171 !important;
    }

.back-from-settings {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

    .back-from-settings h4 {
        font-size: 16px;
        font-weight: 400;
        color: #6B6B6B;
        margin-left: 10px;
    }

    .back-from-settings span {
        /* font-size: 25px;
   font-weight: 400;
   color: #6B6B6B; */
        color: #6B6B6B;
    }

.info-chat {
    width: 100%;
    padding: 2rem 2.5rem .9rem 2.5rem;
    color: #717171;
}

    .info-chat h4 {
        font-size: 25px;
        color: #646464;
        font-weight: 400;
    }

    .info-chat em {
        color: #646464;
    }

    .info-chat .info-theme {
        display: flex;
        align-items: center;
    }

        .info-chat .info-theme div {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-left: 10px;
            margin-top: 15px;
        }

    .info-chat p {
        margin-top: 15px;
    }

.back-to-contact {
    display: none;
}

@media(max-width:1150px) {
    .back-to-contact {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 15px;
    }
}

@media(min-height:700px) {
    #chat-detail .top-chat-content {
        height: 8%;
    }

    #chat-detail .center-chat-content {
        height: calc(100% - 16%);
    }

    #chat-detail .bottom-chat-content {
        height: 8%;
    }

    .center-chat-content-no-messages {
        height: calc(100% - 16%);
    }
}

@media(min-width:1150px) {
    #chat-detail .top-chat-content {
        padding: 10px 25px;
    }
}

@media(min-width: 1650px) {
    #chat-detail .top-chat-content {
        width: 100%;
        padding: 1rem 2rem;
        height: 8%;
    }

    #chat-detail .center-chat-content {
        /*padding: 1rem 2rem;*/
    }

    #chat-detail .top-chat-content img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    #chat-detail .profile-chat h3 {
        font-size: 19px;
        margin-left: 15px;
    }

    .more-chat {
        display: inline-flex;
        background: #FCFCFC;
        box-shadow: 0px 4px 15px rgba(202, 202, 202, 0.25);
        border-radius: 12px;
        flex-direction: column;
        align-content: center;
        padding: 10px 10px;
        position: absolute;
        top: 5%;
        right: 5%;
        z-index: 100;
    }

    #bottom-bar-chat {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;
    }

    .center-chat-content-no-messages .chat-messages-container img {
        width: 120px;
        height: 120px;
    }

    .center-chat-content-no-messages .chat-messages-container h3 {
        font-size: 25px;
        margin-top: 2rem;
    }

    .chat-input {
        max-height: 75px;
        height: 80%;
    }

    #chat-detail .center-chat-content {
        height: calc(100% - 16%);
    }

    #chat-detail .bottom-chat-content {
        height: 8%;
    }

    .center-chat-content-no-messages {
        height: calc(100% - 16%);
    }

    .send {
        margin-left: 25px;
    }
}
