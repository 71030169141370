.chat-master-component {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chat-master-component-container {
    height: 82vh;
    overflow: hidden;
    position: relative;
}

.chat-master-component-wrapper {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

body {
    overflow: hidden;
}

@media(min-width: 1150px) {
    .chat-master-component-container {
        height: 87vh;
    }

    .chat-master-component-wrapper {
        display: flex;
        /*align-items: center;*/
        justify-content: space-between;
        width: 95%;
        margin: 0 auto;
        height: 100%;
    }
}

@media(min-width: 1650px) {
    .chat-master-component-container {
        height: 87vh;
    }
}
