/*------------------------------------------------------------------
DARK SKIN VARIABLES STYLESHEET

Last change: [30 June, 2020]

Chanelog: 

-- Added styles for appbar
-------------------------------------------------------------------*/
$skin-bg:                               #383f44;
$skin-input-bg:                         #2a2a2a;
$skin-input-border-color:               #2a2a2a;
$skin-input-caret-color:                #adb5bd;
$skin-btn-border-color:                 #2a2a2a;
$skin-btn-bg:                           #2a2a2a;
$skin-btn-minimal-color:                #495057;


$skin-border:                           #2b2b2f;
$skin-avatar-status-border:             #323333;

$skin-navigation-bg:                    #383f44;
$skin-sidebar-header-bg:                #383f44;
$skin-chat-info-bg:                     #383f44;
$skin-main-bg:                          #323333;
$skin-sidebar-bg:                       #2d2d2d;

$skin-message-unread-bg:                #383f44;
$skin-left-message-bg:                  #383f44;
$skin-right-message-bg:                 #665dfe;
$skin-dropdown-menu-bg:                 #2b2b2b;

$skin-message-divider-bg:               #424242;

$skin-left-message-color:               #b9b9b9;
$skin-right-message-color:              #ffffff;



$skin-avatar-box-shadow:                0 0 1px 1px rgba(0, 0, 0, 0.1);

$skin-page-main-heading-bg:             #383f44;
$skin-card-header-footer-bg:            #2a2a2a;
$skin-card-bg:                          #383f44;
$skin-list-group-bg:                    #383f44;
$skin-modal-content-bg:                 #383f44;
$skin-dropdown-item-focus-bg:           #383f44;
$skin-dropdown-item-color:              rgba(197, 201, 223, 0.8);


$skin-contacts-link-border-color:       #4b4b60;
$skin-message-unread-border-color:      #383f44;
$skin-contacts-texts-color:             #e8e8e8;
$skin-message-divider-color:            #b0b5b9;


$skin-appnavbar-bg:                     #383f44;
$skin-appbar-head-bg:                   #383f44;
$skin-appnavlink-color:                 #b1b1b1;
$skin-appnavbar-footer-bg:              #383f44;
$skin-appnavbar-content-bg:             #323333;
$skin-note-bg:                          #383f44;
$skin-dd-border-color:                  #4b4b60;


$skin-textarea-color:                   #b9b9b9;
$skin-textarea-caretcolor:              #adb5bd;
$skin-textarea-bg:                      #2a2a2a;
$skin-textarea-border-color:            #2a2a2a;
$skin-textarea-focus-color:             #fff;
$skin-modal-form-color:                 #b9b9b9;